import hwwThree from '../../assets/Icons/check-mark.png'
import hwwFour from '../../assets/Icons/gift.png'
import hwwTwo from '../../assets/Icons/feature-selection.png'
import hwwOne from '../../assets/Icons/brainstorm.png'
import { ActionTypes } from '../Action Types'
const data = [{
    img: hwwOne,
    title: 'HWW_STEP_ONE_TITLE',
    desc: [
        'HWW_STEP_ONE_DESC'
    ]
},
{
    img: hwwTwo,
    title: 'HWW_STEP_TWO_TITLE',
    desc: [
        'HWW_STEP_TWO_DESC'
    ]
},
{
    img: hwwThree,
    title: 'HWW_STEP_THREE_TITLE',
    desc: [
        'HWW_STEP_THREE_DESC'
    ]
},
{
    img: hwwFour,
    title: 'HWW_STEP_FOUR_TITLE',
    desc: [
        'HWW_STEP_FOUR_DESC'
    ]
}]
export function fetchHww() {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.hww.FETCH_HWW_REQUEST })
        dispatch({ type: ActionTypes.hww.FETCH_HWW_REQUEST_SUCCESS, payload: data })
    }
}

export function unloadHww() {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.hww.RESET_HWW_REQUEST });
    }
}