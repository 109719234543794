import { FetchIp, SubmitIp } from "./IpActions"
import { addEvent, clearEvents, fetchContents, submitEvents, updateLocale } from "./applicationActions"
import { resetConsult, resetContact, submitConsultRequest, submitContactRequest } from "./contactActions"
import { fetchCounter, unloadCounter } from "./counterActions"
import { fetchFaqRequest, unloadFaq } from "./faqActions"
import { fetchHww, unloadHww } from "./hwwActions"
import { resetNewsLetter, submitNewsLetter } from "./newsLetterActions"

const Actions = {
    HWW: {
        load: fetchHww,
        unload: unloadHww
    },
    FAQ: {
        load: fetchFaqRequest,
        unload: unloadFaq
    },
    COUNTER: {
        load: fetchCounter,
        unload: unloadCounter
    },
    NEWSLETTER: {
        submit: submitNewsLetter,
        unload: resetNewsLetter,
    },
    IP: {
        load: FetchIp,
        submit: SubmitIp
    },
    CONTACT: {
        submit: submitContactRequest,
        unload: resetContact
    },
    CONSULT: {
        submit: submitConsultRequest,
        unload: resetConsult
    },
    APPLICATION: {
        LOCALE: {
            load: fetchContents,
            update: updateLocale
        },
        tracking: {
            addEvent: addEvent,
            clearEvent: clearEvents,
            submitEvents: submitEvents
        }
    }
}
export default Actions;