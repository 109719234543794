import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { thunk } from 'redux-thunk'
import Reducers  from './Reducer'
export const Store = configureStore({
    reducer: combineReducers(Reducers),
    middleware:  () => {
        return [thunk]
      },
    devTools: process.env.NODE_ENV !== 'production',
})