export const CONSTANT = {
    API_ENDPOINT: {
        IP_INFO: "https://ipapi.co/json/",
        IP: '/IP'
    },
    HTTP_METHOD: {
        GET: 'GET',
        PUT: 'PUT',
        POST: 'POST',
        PATCH: 'PATCH',
        DELETE: 'DELETE'
    },
    LANGUAGE: {
        ENGLISH: "EN",
        HINDI: "हि"
    },
    KEYWORDS: {
        LANGUAGE: "lang"
    },

    REDUCERS: {
        HOW_WE_WORK: "HOW_WE_WORK",
        FAQ: "FAQ",
        COUNT: "COUNT",
        NEWS_LETTERS: "NEWS_LETTERS",
        IP: "IP",
        CONTACT: "CONTACT",
        CONSULT: "CONSULT",
        SERVICES: "SERVICES",
        TEAM: "TEAM",
        WHY_US: "WHY_US",
        APPLICATION: "APPLICATION",
    },
    ROUTES: {
        HOME: '/',
        ABOUT: '/about',
        SERVICES: '/services',
        PORTFOLIO: '/portfolio',
        NEWS_LETTERS: '/newsletters',
        FAQ: '/faq',
        CONTACT: '/contact',
        CONSULT: '/consult',
        HOW_WE_WORK: '/how-we-work',
        TEAM: '/team',
        HASH: '#',
        BLOG: '/blog',
        WHY_US: '/why-us'
    }
}
export const statickeys = {
    SERVICE_MODULAR_KITCHEN: 'SERVICE_MODULAR_KITCHEN',
    SERVICE_STORAGE_WORDROBE: 'SERVICE_STORAGE_WORDROBE',
    SERVICE_CROCKERY_UNIT: 'SERVICE_CROCKERY_UNIT',
    SERVICE_SPACE_SAVING: 'SERVICE_SPACE_SAVING',
    SERVICE_ELECRICAL_CHANGE: 'SERVICE_ELECRICAL_CHANGE',
    SERVICE_POP: 'SERVICE_POP',
    SERVICE_TV_UNIT: 'SERVICE_TV_UNIT',
    SERVICE_STUDY_TABLE: 'SERVICE_STUDY_TABLE',
    SERVICE_FALSE_CEILING: 'SERVICE_FALSE_CEILING',
    SERVICE_LIGHTS: 'SERVICE_LIGHTS',
    SERVICE_WALLPAPER: 'SERVICE_WALLPAPER',
    SERVICE_PAINTING: 'SERVICE_PAINTING',
    SERVICE_BATHROOM_DESIGN: 'SERVICE_BATHROOM_DESIGN',
    SERVICE_FURNITURE_DESIGN: 'SERVICE_FURNITURE_DESIGN',
    SERVICE_POOJA_UNIT: 'SERVICE_POOJA_UNIT',
    SERVICE_BEDROOM_DECORATION: 'SERVICE_BEDROOM_DECORATION',
    SERVICE_STORE_SHOP: 'SERVICE_STORE_SHOP',
    SERVICE_KIDS_SPACE: 'SERVICE_KIDS_SPACE',
    SERVICE_DINING_SPACE: 'SERVICE_DINING_SPACE',
    SERVICE_APPLIANCE_SELECTION: 'SERVICE_APPLIANCE_SELECTION',
    SERVICE_CABINATE_HARDWARE_DESIGN: 'SERVICE_CABINATE_HARDWARE_DESIGN',
    SERVICE_CLOSET_DESIGN: 'SERVICE_CLOSET_DESIGN',
    SERVICE_COMMERCIAL_INTERIOR: 'SERVICE_COMMERCIAL_INTERIOR',
    SERVICE_CUSTOM_ART: 'SERVICE_CUSTOM_ART',
    SERVICE_DINING_ROOM: 'SERVICE_DINING_ROOM',
    SERVICE_DOOR_DESIGN: 'SERVICE_DOOR_DESIGN',
    SERVICE_FLOORING_SELECTION: 'SERVICE_FLOORING_SELECTION',
    SERVICE_HOME_STAGING: 'SERVICE_HOME_STAGING',
    SERVICE_LIVING_ROOM_DESIGN: 'SERVICE_LIVING_ROOM_DESIGN',
    SERVICE_INTERIOR_ARCHITECTURE_DESIGN: 'SERVICE_INTERIOR_ARCHITECTURE_DESIGN',
    SERVICE_REFURBISHMENT: 'SERVICE_REFURBISHMENT',
    SERVICE_RESTAURANT_DESIGN: 'SERVICE_RESTAURANT_DESIGN',
    SERVICE_ROOM_PLANNING: 'SERVICE_ROOM_PLANNING',
    SERVICE_OFFICE_DESIGN: 'SERVICE_OFFICE_DESIGN',
    SERVICE_CUPBOARD_DESIGN: 'SERVICE_CUPBOARD_DESIGN',
    SERVICE_CRAFT_INTERIORS: 'SERVICE_CRAFT_INTERIORS',
    ABOUT_US_MAIN_TITLE: 'ABOUT_US_MAIN_TITLE',
    ABOUT_US_DESCRIPTION: 'ABOUT_US_DESCRIPTION',
    SLEEK_ARCHITECTURE_TITLE: 'SLEEK_ARCHITECTURE_TITLE',
    ABOUT_US_SECOND_PARA: 'ABOUT_US_SECOND_PARA',
    COMMON_CONTENT_AMPERSAND: 'COMMON_CONTENT_AMPERSAND',
    COMMON_CONTENT_ALONG_WITH: 'COMMON_CONTENT_ALONG_WITH',
    COMMON_CONTENT_BEST_PRICE: 'COMMON_CONTENT_BEST_PRICE',
    COMMON_CONTENT_SUPER_QUALITY: 'COMMON_CONTENT_SUPER_QUALITY',
    COMMON_CONTENT_SAFETY_ASSURANCE: 'COMMON_CONTENT_SAFETY_ASSURANCE',
    COMMON_CONTENT_PHONE: 'COMMON_CONTENT_PHONE',
    COMMON_CONTENT_LOCATION: 'COMMON_CONTENT_LOCATION',
    COMMON_CONTENT_EMAIL: 'COMMON_CONTENT_EMAIL',
    COMMON_CONTENT_STATE: 'COMMON_CONTENT_STATE',
    COMMON_CONTENT_CITY: 'COMMON_CONTENT_CITY',
    COMMON_CONTENT_PIN_CODE: 'COMMON_CONTENT_PIN_CODE',
    COMMON_CONTENT_SUBMIT: 'COMMON_CONTENT_SUBMIT',
    COMMON_CONTENT_USEFUL_LINK: 'COMMON_CONTENT_USEFUL_LINK',
    COMMON_BUTTON_SUBSCRIBE: 'COMMON_BUTTON_SUBSCRIBE',
    COMMON_BUTTON_SUBSCRIBING: 'COMMON_BUTTON_SUBSCRIBING',
    COMMON_BUTTON_CONTACT_US: 'COMMON_BUTTON_CONTACT_US',
    COMMON_BUTTON_SEND_MESSAGE: 'COMMON_BUTTON_SEND_MESSAGE',
    COMMON_BUTTON_SUBMIT: 'COMMON_BUTTON_SUBMIT',
    COMMMON_BUTTON_VIEW_ALL: 'COMMMON_BUTTON_VIEW_ALL',
    COMMON_CONTENT_COMPANY_NAME: 'COMMON_CONTENT_COMPANY_NAME',
    COMMON_CONTENT_ALL_RIGHTS_RESERVED: 'COMMON_CONTENT_ALL_RIGHTS_RESERVED',
    COMMON_CONTENT_COPYRIGHT: 'COMMON_CONTENT_COPYRIGHT',
    COMMON_PLACEHOLDER_NAME: 'COMMON_PLACEHOLDER_NAME',
    COMMON_PLACEHOLDER_EMAIL: 'COMMON_PLACEHOLDER_EMAIL',
    COMMON_PLACEHOLDER_SUBJECT: 'COMMON_PLACEHOLDER_SUBJECT',
    COMMON_PLACEHOLDER_MESSAGE: 'COMMON_PLACEHOLDER_MESSAGE',
    COMMON_PLACEHOLDER_PHONE: 'COMMON_PLACEHOLDER_PHONE',
    COMMON_PLACEHOLDER_PINCODE: 'COMMON_PLACEHOLDER_PINCODE',
    COMMON_PLACEHOLDER_FAQ: 'COMMON_PLACEHOLDER_FAQ',
    COMMON_PLACEHOLDER_SELECT: 'COMMON_PLACEHOLDER_SELECT',
    COMMON_PLACEHOLDER_FAQ_NOTFOUND: 'COMMON_PLACEHOLDER_FAQ_NOTFOUND',
    COMMON_LABEL_DESIGNED_BY: 'COMMON_LABEL_DESIGNED_BY',
    CONTACT_ADDRESS: 'CONTACT_ADDRESS',
    NEWLETTER_TITLE: 'NEWLETTER_TITLE',
    NEWLETTER_DESC: 'NEWLETTER_DESC',
    USER_WHATSAPP_CONFIRMATION: 'USER_WHATSAPP_CONFIRMATION',
    COMMON_CONTENT_OUR_TEAM: 'COMMON_CONTENT_OUR_TEAM',
    TEAM_TITLE: 'TEAM_TITLE',
    BOOK_FREE_SESSION: 'BOOK_FREE_SESSION',
    COMMON_CONTENT_SERVICES: 'COMMON_CONTENT_SERVICES',
    COMMON_CONTENT_FEATURES: 'COMMON_CONTENT_FEATURES',
    COMMON_CONTENT_FEATURED_PROJECTS: 'COMMON_CONTENT_FEATURED_PROJECTS',
    FEATURED_PROJECTS_DESC: 'FEATURED_PROJECTS_DESC',
    COMMON_CONTENT_GALLERY: 'COMMON_CONTENT_GALLERY',
    GALLERY_DESC: 'GALLERY_DESC',
    FEATURES_DESC: 'FEATURES_DESC',
    COMMON_CONTENT_ABOUT_US: 'COMMON_CONTENT_ABOUT_US',
    COMMON_CONTENT_FAQ: 'COMMON_CONTENT_FAQ',
    COMMON_CONTENT_HOME: 'COMMON_CONTENT_HOME',
    COMMON_CONTENT_PORTFOLIO: 'COMMON_CONTENT_PORTFOLIO',
    COMMON_CONTENT_CONTACT: 'COMMON_CONTENT_CONTACT',
    COMMON_CONTENT_BLOG: 'COMMON_CONTENT_BLOG',
    COMMON_CONTENT_WHY_US: 'COMMON_CONTENT_WHY_US',
    COMMON_CONTENT_DECORATOR: 'COMMON_CONTENT_DECORATOR',
    CONTENT_DECORATOR: 'CONTENT_DECORATOR',
    COMMON_CONTENT_FULL_NAME: 'COMMON_CONTENT_FULL_NAME',
    WHY_US_TITLE: 'WHY_US_TITLE',
    WHY_US_DESC: 'WHY_US_DESC',
    WHY_US_PARAGRAPH: 'WHY_US_PARAGRAPH',
    WHY_US_ITEM_ONE: 'WHY_US_ITEM_ONE',
    WHY_US_ITEM_ONE_DESC: 'WHY_US_ITEM_ONE_DESC',
    WHY_US_ITEM_TWO: 'WHY_US_ITEM_TWO',
    WHY_US_ITEM_TWO_DESC: 'WHY_US_ITEM_TWO_DESC',
    WHY_US_ITEM_THREE: 'WHY_US_ITEM_THREE',
    WHY_US_ITEM_THREE_DESC: 'WHY_US_ITEM_THREE_DESC',
    WHY_US_ITEM_FOUR: 'WHY_US_ITEM_FOUR',
    WHY_US_ITEM_FOUR_DESC: 'WHY_US_ITEM_FOUR_DESC',
    ABOUT_US_PARAGRAPH_ONE: 'ABOUT_US_PARAGRAPH_ONE',
    ABOUT_US_PARAGRAPH_TWO: 'ABOUT_US_PARAGRAPH_TWO',
    ABOUT_US_PARAGRAPH_THREE: 'ABOUT_US_PARAGRAPH_THREE',
    ABOUT_US_PARAGRAPH_FOUR: 'ABOUT_US_PARAGRAPH_FOUR',
    ABOUT_US_PARAGRAPH_FIVE: 'ABOUT_US_PARAGRAPH_FIVE',
    ABOUT_US_PARAGRAPH_SIX: 'ABOUT_US_PARAGRAPH_SIX',
    ABOUT_US_GOAL: 'ABOUT_US_GOAL',
    ABOUT_US_MISSION: 'ABOUT_US_MISSION',
    ABOUT_US_PARAGRAPH_SEVEN: 'ABOUT_US_PARAGRAPH_SEVEN',
    TEAM_ROLE_TWO: 'TEAM_ROLE_TWO',
    SANDEEP_VISHWAKARMA: 'SANDEEP_VISHWAKARMA',
    JITENDRA_VISHWAKARMA: 'JITENDRA_VISHWAKARMA',
    SANDEEP_VISHWAKARMA_QUOTE: 'SANDEEP_VISHWAKARMA_QUOTE',
    JITENDRA_VISHWAKARMA_QUOTE: 'JITENDRA_VISHWAKARMA_QUOTE',
    SECTION_CONTENT_TITLE: 'SECTION_CONTENT_TITLE',
    SECTION_TITLE_HEADER: 'SECTION_TITLE_HEADER',
    SECTION_PARAGRAPH_ONE: 'SECTION_PARAGRAPH_ONE',
    SECTION_PARAGRAPH_TWO: 'SECTION_PARAGRAPH_TWO',
    SECTION_PARAGRAPH_THREE: 'SECTION_PARAGRAPH_THREE',
    SECTION_PARAGRAPH_FOUR: 'SECTION_PARAGRAPH_FOUR',
    SECTION_PARAGRAPH_FIVE: 'SECTION_PARAGRAPH_FIVE',
    SECTION_PARAGRAPH_SIX: 'SECTION_PARAGRAPH_SIX',
    SECTION_PARAGRAPH_LIST_ITEM_ONE: 'SECTION_PARAGRAPH_LIST_ITEM_ONE',
    SECTION_PARAGRAPH_LIST_ITEM_TWO: 'SECTION_PARAGRAPH_LIST_ITEM_TWO',
    SECTION_PARAGRAPH_LIST_ITEM_THREE: 'SECTION_PARAGRAPH_LIST_ITEM_THREE',
    GOAL: 'GOAL',
    MISSION: 'MISSION',
    FEATURE_ITEM_ONE: 'FEATURE_ITEM_ONE',
    FEATURE_ITEM_TWO: 'FEATURE_ITEM_TWO',
    FEATURE_ITEM_THREE: 'FEATURE_ITEM_THREE',
    FEATURE_ITEM_FOUR: 'FEATURE_ITEM_FOUR',
    FEATURE_ITEM_FIVE: 'FEATURE_ITEM_FIVE',
    FEATURE_ITEM_SIX: 'FEATURE_ITEM_SIX',
    FEATURE_ITEM_ONE_DESC: 'FEATURE_ITEM_ONE_DESC',
    FEATURE_ITEM_TWO_DESC: 'FEATURE_ITEM_TWO_DESC',
    FEATURE_ITEM_THREE_DESC: 'FEATURE_ITEM_THREE_DESC',
    FEATURE_ITEM_FOUR_DESC: 'FEATURE_ITEM_FOUR_DESC',
    FEATURE_ITEM_FIVE_DESC: 'FEATURE_ITEM_FIVE_DESC',
    FEATURE_ITEM_SIX_DESC: 'FEATURE_ITEM_SIX_DESC',
    PARAGRAPH: 'PARAGRAPH',
    ITEM_ONE: 'ITEM_ONE',
    ITEM_TWO: 'ITEM_TWO',
    ITEM_THREE: 'ITEM_THREE',
    ITEM_THREE_DESC: 'ITEM_THREE_DESC',
    ITEM_FOUR: 'ITEM_FOUR',
    ITEM_FOUR_DESC: 'ITEM_FOUR_DESC',
    HWW_TITLE: 'HWW_TITLE',
    HWW_DESC: 'HWW_DESC',
    HWW_STEP_ONE_TITLE: 'HWW_STEP_ONE_TITLE',
    HWW_STEP_ONE_DESC: 'HWW_STEP_ONE_DESC',
    HWW_STEP_TWO_TITLE: 'HWW_STEP_TWO_TITLE',
    HWW_STEP_TWO_DESC: 'HWW_STEP_TWO_DESC',
    HWW_STEP_THREE_TITLE: 'HWW_STEP_THREE_TITLE',
    HWW_STEP_THREE_DESC: 'HWW_STEP_THREE_DESC',
    HWW_STEP_FOUR_TITLE: 'HWW_STEP_FOUR_TITLE',
    HWW_STEP_FOUR_DESC: 'HWW_STEP_FOUR_DESC',
    HAPPY_CUSTOMERS_TEXT: 'HAPPY_CUSTOMERS_TEXT',
    YOE_TEXT: 'YOE_TEXT',
    PROJECTS_TEXT: 'PROJECTS_TEXT',
    HARD_WORKING_EMPLOYEE_TEXT: 'HARD_WORKING_EMPLOYEE_TEXT',
    HERO_ITEM_ONE: 'HERO_ITEM_ONE',
    HERO_ITEM_TWO: 'HERO_ITEM_TWO',
    HERO_ITEM_THREE: 'HERO_ITEM_THREE',
    CTA_TITLE: 'CTA_TITLE',
    CTA_DESC: 'CTA_DESC',
    PORTFOLIO_TV_UNIT: 'PORTFOLIO_TV_UNIT',
    PORTFOLIO_BEDROOM: 'PORTFOLIO_BEDROOM',
    PORTFOLIO_BATHROOM: 'PORTFOLIO_BATHROOM',
    PORTFOLIO_FURNITURE: 'PORTFOLIO_FURNITURE',
    PORTFOLIO_KITCHEN: 'PORTFOLIO_KITCHEN',
    PORTFOLIO_LIVING_SPACE: 'PORTFOLIO_LIVING_SPACE',
    COMMON_CONTENT_OTHERS: 'COMMON_CONTENT_OTHERS',
    COMMON_CONTENT_ALL: 'COMMON_CONTENT_ALL',
    FAQ_QUE_1: 'FAQ_QUE_1',
    FAQ_ANS_1: 'FAQ_ANS_1',
    FAQ_QUE_2: 'FAQ_QUE_2',
    FAQ_ANS_2: 'FAQ_ANS_2',
    FAQ_QUE_3: 'FAQ_QUE_3',
    FAQ_ANS_3: 'FAQ_ANS_3',
    FAQ_QUE_4: 'FAQ_QUE_4',
    FAQ_ANS_4: 'FAQ_ANS_4',
    FAQ_QUE_5: 'FAQ_QUE_5',
    FAQ_ANS_5: 'FAQ_ANS_5',
    FAQ_QUE_6: 'FAQ_QUE_6',
    FAQ_ANS_6: 'FAQ_ANS_6',
    FAQ_QUE_7: 'FAQ_QUE_7',
    FAQ_ANS_7: 'FAQ_ANS_7',
    FAQ_QUE_8: 'FAQ_QUE_8',
    FAQ_ANS_8: 'FAQ_ANS_8',
    FAQ_QUE_9: 'FAQ_QUE_9',
    FAQ_ANS_9: 'FAQ_ANS_9',
    FAQ_QUE_10: 'FAQ_QUE_10',
    FAQ_ANS_10: 'FAQ_ANS_10',
    FAQ_QUE_11: 'FAQ_QUE_11',
    FAQ_ANS_11: 'FAQ_ANS_11',
    FAQ_QUE_12: 'FAQ_QUE_12',
    FAQ_ANS_12: 'FAQ_ANS_12',
    FAQ_QUE_13: 'FAQ_QUE_13',
    FAQ_ANS_13: 'FAQ_ANS_13',
    FAQ_QUE_14: 'FAQ_QUE_14',
    FAQ_ANS_14: 'FAQ_ANS_14',
    FAQ_QUE_15: 'FAQ_QUE_15',
    FAQ_ANS_15: 'FAQ_ANS_15',
    FAQ_QUE_16: 'FAQ_QUE_16',
    FAQ_ANS_16: 'FAQ_ANS_16',
    FAQ_QUE_17: 'FAQ_QUE_17',
    FAQ_ANS_17: 'FAQ_ANS_17',
    FAQ_QUE_18: 'FAQ_QUE_18',
    FAQ_ANS_18: 'FAQ_ANS_18',
    PORTFOLIO_TV_TAG: 'PORTFOLIO_TV_TAG',
    PORTFOLIO_BEDROOM_TAG: 'PORTFOLIO_BEDROOM_TAG',
    PORTFOLIO_BATHROOM_TAG: 'PORTFOLIO_BATHROOM_TAG',
    PORTFOLIO_FURNITURE_TAG: 'PORTFOLIO_FURNITURE_TAG',
    PORTFOLIO_KITCHEN_TAG: 'PORTFOLIO_KITCHEN_TAG',
    PORTFOLIO_LIVING_SPACE_TAG: 'PORTFOLIO_LIVING_SPACE_TAG',
    PORTFOLIO_OTHERS_TAG: 'PORTFOLIO_OTHERS_TAG',
    PORTFOLIO_ALL_TAG: 'PORTFOLIO_ALL_TAG',
    PORTFOLIO_ITEM_TITLE_1: 'PORTFOLIO_ITEM_TITLE_1',
    PORTFOLIO_ITEM_DESC_1: 'PORTFOLIO_ITEM_DESC_1',
    PORTFOLIO_ITEM_TITLE_2: 'PORTFOLIO_ITEM_TITLE_2',
    PORTFOLIO_ITEM_DESC_2: 'PORTFOLIO_ITEM_DESC_2',
    PORTFOLIO_ITEM_TITLE_3: 'PORTFOLIO_ITEM_TITLE_3',
    PORTFOLIO_ITEM_DESC_3: 'PORTFOLIO_ITEM_DESC_3',
    PORTFOLIO_ITEM_TITLE_4: 'PORTFOLIO_ITEM_TITLE_4',
    PORTFOLIO_ITEM_DESC_4: 'PORTFOLIO_ITEM_DESC_4',
    PORTFOLIO_ITEM_TITLE_5: 'PORTFOLIO_ITEM_TITLE_5',
    PORTFOLIO_ITEM_DESC_5: 'PORTFOLIO_ITEM_DESC_5',
    PORTFOLIO_ITEM_TITLE_6: 'PORTFOLIO_ITEM_TITLE_6',
    PORTFOLIO_ITEM_DESC_6: 'PORTFOLIO_ITEM_DESC_6',
    PORTFOLIO_ITEM_TITLE_7: 'PORTFOLIO_ITEM_TITLE_7',
    PORTFOLIO_ITEM_DESC_7: 'PORTFOLIO_ITEM_DESC_7',
    PORTFOLIO_ITEM_TITLE_8: 'PORTFOLIO_ITEM_TITLE_8',
    PORTFOLIO_ITEM_DESC_8: 'PORTFOLIO_ITEM_DESC_8',
    PORTFOLIO_ITEM_TITLE_9: 'PORTFOLIO_ITEM_TITLE_9',
    PORTFOLIO_ITEM_DESC_9: 'PORTFOLIO_ITEM_DESC_9',
    PORTFOLIO_ITEM_TITLE_10: 'PORTFOLIO_ITEM_TITLE_10',
    PORTFOLIO_ITEM_DESC_10: 'PORTFOLIO_ITEM_DESC_10',
    PORTFOLIO_ITEM_TITLE_11: 'PORTFOLIO_ITEM_TITLE_11',
    PORTFOLIO_ITEM_DESC_11: 'PORTFOLIO_ITEM_DESC_11',
    PORTFOLIO_ITEM_TITLE_12: 'PORTFOLIO_ITEM_TITLE_12',
    PORTFOLIO_ITEM_DESC_12: 'PORTFOLIO_ITEM_DESC_12',
    PORTFOLIO_ITEM_TITLE_13: 'PORTFOLIO_ITEM_TITLE_13',
    PORTFOLIO_ITEM_DESC_13: 'PORTFOLIO_ITEM_DESC_13',
    PORTFOLIO_ITEM_TITLE_14: 'PORTFOLIO_ITEM_TITLE_14',
    PORTFOLIO_ITEM_DESC_14: 'PORTFOLIO_ITEM_DESC_14',
    PORTFOLIO_ITEM_TITLE_15: 'PORTFOLIO_ITEM_TITLE_15',
    PORTFOLIO_ITEM_DESC_15: 'PORTFOLIO_ITEM_DESC_15',
    PORTFOLIO_ITEM_TITLE_16: 'PORTFOLIO_ITEM_TITLE_16',
    PORTFOLIO_ITEM_DESC_16: 'PORTFOLIO_ITEM_DESC_16',
    PORTFOLIO_ITEM_TITLE_17: 'PORTFOLIO_ITEM_TITLE_17',
    PORTFOLIO_ITEM_DESC_17: 'PORTFOLIO_ITEM_DESC_17',
    PORTFOLIO_ITEM_TITLE_18: 'PORTFOLIO_ITEM_TITLE_18',
    PORTFOLIO_ITEM_DESC_18: 'PORTFOLIO_ITEM_DESC_18',
    PORTFOLIO_ITEM_TITLE_19: 'PORTFOLIO_ITEM_TITLE_19',
    PORTFOLIO_ITEM_DESC_19: 'PORTFOLIO_ITEM_DESC_19',
    PORTFOLIO_ITEM_TITLE_20: 'PORTFOLIO_ITEM_TITLE_20',
    PORTFOLIO_ITEM_DESC_20: 'PORTFOLIO_ITEM_DESC_20',
    PORTFOLIO_ITEM_TITLE_21: 'PORTFOLIO_ITEM_TITLE_21',
    PORTFOLIO_ITEM_DESC_21: 'PORTFOLIO_ITEM_DESC_21',
    PORTFOLIO_ITEM_TITLE_22: 'PORTFOLIO_ITEM_TITLE_22',
    PORTFOLIO_ITEM_DESC_22: 'PORTFOLIO_ITEM_DESC_22',
    PORTFOLIO_ITEM_TITLE_23: 'PORTFOLIO_ITEM_TITLE_23',
    PORTFOLIO_ITEM_DESC_23: 'PORTFOLIO_ITEM_DESC_23',
    PORTFOLIO_ITEM_TITLE_24: 'PORTFOLIO_ITEM_TITLE_24',
    PORTFOLIO_ITEM_DESC_24: 'PORTFOLIO_ITEM_DESC_24',
    PORTFOLIO_ITEM_TITLE_25: 'PORTFOLIO_ITEM_TITLE_25',
    PORTFOLIO_ITEM_DESC_25: 'PORTFOLIO_ITEM_DESC_25',
    PORTFOLIO_ITEM_TITLE_26: 'PORTFOLIO_ITEM_TITLE_26',
    PORTFOLIO_ITEM_DESC_26: 'PORTFOLIO_ITEM_DESC_26',
    PORTFOLIO_ITEM_TITLE_27: 'PORTFOLIO_ITEM_TITLE_27',
    PORTFOLIO_ITEM_DESC_27: 'PORTFOLIO_ITEM_DESC_27',
    PORTFOLIO_ITEM_TITLE_28: 'PORTFOLIO_ITEM_TITLE_28',
    PORTFOLIO_ITEM_DESC_28: 'PORTFOLIO_ITEM_DESC_28',
    PORTFOLIO_ITEM_TITLE_29: 'PORTFOLIO_ITEM_TITLE_29',
    PORTFOLIO_ITEM_DESC_29: 'PORTFOLIO_ITEM_DESC_29',
    PORTFOLIO_ITEM_TITLE_30: 'PORTFOLIO_ITEM_TITLE_30',
    PORTFOLIO_ITEM_DESC_30: 'PORTFOLIO_ITEM_DESC_30',
    PORTFOLIO_ITEM_TITLE_31: 'PORTFOLIO_ITEM_TITLE_31',
    PORTFOLIO_ITEM_DESC_31: 'PORTFOLIO_ITEM_DESC_31',
    PORTFOLIO_ITEM_TITLE_32: 'PORTFOLIO_ITEM_TITLE_32',
    PORTFOLIO_ITEM_DESC_32: 'PORTFOLIO_ITEM_DESC_32',
    PORTFOLIO_ITEM_TITLE_33: 'PORTFOLIO_ITEM_TITLE_33',
    PORTFOLIO_ITEM_DESC_33: 'PORTFOLIO_ITEM_DESC_33',
    PORTFOLIO_ITEM_TITLE_34: 'PORTFOLIO_ITEM_TITLE_34',
    PORTFOLIO_ITEM_DESC_34: 'PORTFOLIO_ITEM_DESC_34',
    PORTFOLIO_ITEM_TITLE_35: 'PORTFOLIO_ITEM_TITLE_35',
    PORTFOLIO_ITEM_DESC_35: 'PORTFOLIO_ITEM_DESC_35',
    PORTFOLIO_ITEM_TITLE_36: 'PORTFOLIO_ITEM_TITLE_36',
    PORTFOLIO_ITEM_DESC_36: 'PORTFOLIO_ITEM_DESC_36',
}

export const appSettings = {
    HAPPY_CUSTOMERS: 200,
    YOE: 12,
    PROJECTS: 250,
    EMPLOYEES: 50,
    WARRANTY: 10,
    FeatureFlag: {
        useBreadCumb: false,
    }
}