import { useSelector } from 'react-redux';
import { CONSTANT } from '../CONSTANT';
import { Store } from '../Redux/Store';
import { formatString } from '.';

function useTranslation() {
  const { translation } = useSelector(state => state[CONSTANT.REDUCERS.APPLICATION])
  function T(key, params = {}) {
    let val = translation[key] ?? key
    return val === key ? key : formatString(val, params)
  }

  return T
}
function getState() {
  return Store.getState()[CONSTANT.REDUCERS.APPLICATION]
}
export function Translate(key, params = {}) {
  const { translation } = getState()
  let val = translation[key] ?? key
  return val === key ? key : formatString(val, params)
}
export default useTranslation