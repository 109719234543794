import InvokeApi from "../../Utilities/apiHelper"
import axios from 'axios'
import { ActionTypes } from "../Action Types"
import { RequestContext } from "../../Utilities/HelperClasses"
import { CONSTANT } from "../../CONSTANT"

function FetchIp() {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.ip.FETCH_IP_REQUEST })
        axios.get(CONSTANT.API_ENDPOINT.IP_INFO).then(res => {
            localStorage.setItem('userDetails', JSON.stringify(res.data));
            dispatch({ type: ActionTypes.ip.FETCH_IP_REQUEST_SUCCESS, payload: res.data })
        }).catch(err => {
            dispatch({ type: ActionTypes.ip.FETCH_IP_REQUEST_FAILURE, payload: err })
        })
    }
}

function SubmitIp() {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.ip.SUBMI_IP_REQUEST })
        const details = localStorage.getItem('userDetails');
        if (details) {
            InvokeApi(new RequestContext(CONSTANT.API_ENDPOINT.IP, CONSTANT.HTTP_METHOD.POST, {}, JSON.parse(details)))
                .then((res) => {
                    dispatch({ type: ActionTypes.ip.SUBMI_IP_REQUEST_SUCCESS, payload: res })
                })
                .catch(err => {
                    dispatch({ type: ActionTypes.ip.SUBMI_IP_REQUEST_FAILURE, payload: err?.message })
                })
        }
    }
}

export {
    FetchIp, SubmitIp
}