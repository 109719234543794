import { appSettings } from "../../CONSTANT"
import { ActionTypes } from "../Action Types"

const initialState = {
    loading: false,
    err: '',
    whyUsData: [{
        icon: "calendar text-primary",
        title: 'WHY_US_ITEM_ONE',
        key: "Service-1",
        content: 'WHY_US_ITEM_ONE_DESC',
        params: {
            key1: appSettings.YOE
        }
    },
    {
        icon: "check text-success",
        title: 'WHY_US_ITEM_TWO',
        key: "Service-2",
        content: 'WHY_US_ITEM_TWO_DESC',
        params: {
            key1: appSettings.HAPPY_CUSTOMERS
        }
    },
    {
        icon: "star text-danger",
        key: "Service-3",
        title: 'WHY_US_ITEM_THREE',
        content: 'WHY_US_ITEM_THREE_DESC',
        params:{}
    },
    {
        icon: "shield text-dark",
        title: 'WHY_US_ITEM_FOUR',
        key: "Service-4",
        content: 'WHY_US_ITEM_FOUR_DESC',
        params: {
            key1: appSettings.WARRANTY
        }
    }]
}

function whyUsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.why_us.FETCH_WHY_US_REQUEST:
            return {
                ...state,
                loading: true,
                err: ''
            }
        case ActionTypes.why_us.FETCH_WHY_US_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                whyUsData: action.payload
            }
        case ActionTypes.why_us.FETCH_WHY_US_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                whyUsData: [],
                err: action.payload
            }
        default:
            return state
    }
}

export default whyUsReducer;