import { ActionTypes } from "../Action Types"

const intialState = {
    loading: false,
    err: '',
    contactData: [],
    submitted: false,
}

function contactReducer(state = intialState, action) {
    switch (action.type) {
        case ActionTypes.contact.SUBMIT_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
                submitted: false,
                err: ''
            }
        case ActionTypes.contact.SUBMIT_CONTACT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                submitted: true,
                contactData: action.payload
            }
        case ActionTypes.contact.SUBMIT_CONTACT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                submitted: false,
                err: action.payload,
                contactData: []
            }
        case ActionTypes.contact.RESET_CONTACT_REQUEST:
            return {
                ...state,
                ...intialState,
            }
        default:
            return state
    }
}
export default contactReducer;