import { ActionTypes } from "../Action Types"

const initalState = {
    serviceData: [
        {
            icon: "calendar",
            title: "10+ Years",
            key: "Service-1",
            content: "10+ years of Experience"
        },
        {
            icon: "house",
            title: "",
            key: "Service-2",
            content: "200+ happy customers"
        },
        {
            icon: "patch-check-fill",
            key: "Service-3",
            title: "",
            content: "30+ design experts"
        },
        {
            icon: "shield",
            title: "",
            key: "Service-4",
            content: "upto 10 plus year material warranty"
        },
    ],
    loading: false,
    err: ''
}

function servicesReducer(state = initalState, action) {
    switch (action.type) {
        case ActionTypes.services.FETCH_SERVICES_REQUEST:
            return {
                ...state,
                loading: true,
                err: ''
            }
        case ActionTypes.services.FETCH_SERVICES_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                err: '',
                serviceData: action.payload
            }
        case ActionTypes.services.FETCH_SERVICES_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload,
                serviceData: []
            }
        default:
            return state;
    }
}

export default servicesReducer;