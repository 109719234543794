import React from 'react'
import Header from './Template/Header'
import Footer from './Template/Footer'
import { Routes, Route } from 'react-router-dom';
import FloatingButton from './Template/FloatingButton'
import { ToggleSidebar } from './Utilities';
import Loader from './Home/Loader';
import { CONSTANT } from './CONSTANT';
const Main = React.lazy(() => import('./Home/Main'));
const Contact = React.lazy(() => import('./Home/Contact'));
const Portfolio = React.lazy(() => import('./Home/Portfolio/Portfolio'));
const About = React.lazy(() => import('./Home/About'));
const WhyUsComponent = React.lazy(() => import('./Home/WhyUs/WhyUsComponent'));
const Blog = React.lazy(() => import('./Home/Blog/Blog'));
const Services = React.lazy(() => import('./Home/Services/Service'));
const FaqComponent = React.lazy(() => import('./Home/FAQ/FaqComponent'));
function Layout() {
    return (
        <>
            <Header />
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    <Route path={CONSTANT.ROUTES.CONTACT} element={<Contact />}></Route>
                    <Route path={CONSTANT.ROUTES.PORTFOLIO} element={<Portfolio />}></Route>
                    <Route path={CONSTANT.ROUTES.SERVICES} element={<Services />}></Route>
                    <Route path={CONSTANT.ROUTES.ABOUT} element={<About />}></Route>
                    <Route path={CONSTANT.ROUTES.WHY_US} element={<WhyUsComponent />}></Route>
                    <Route path={CONSTANT.ROUTES.FAQ} element={<FaqComponent />}></Route>
                    <Route path={CONSTANT.ROUTES.BLOG} element={<Blog />}></Route>
                    <Route path='*' element={<Main />}></Route>
                </Routes>
            </React.Suspense>
            <FloatingButton handleClick={ToggleSidebar} />
            <Footer />
        </>
    )
}

export default Layout