import { ActionTypes } from "../Action Types"

const initialState = {
    data: {},
    loading: false,
    err: ''
}

function ipReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ip.FETCH_IP_REQUEST:
            return {
                ...state,
                loading: true,
                err: '',
                data: {}
            }
        case ActionTypes.ip.FETCH_IP_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                err: '',
                data: action.payload
            }
        case ActionTypes.ip.FETCH_IP_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload,
                data: {}
            }
        default:
            return state;
    }
}

export default ipReducer 