import React, { useState, useEffect } from 'react'
import toastr from 'toastr';
import { useSelector, useDispatch } from 'react-redux'
import AboutLink from '../Links/AboutLink';
import { CONSTANT, statickeys } from '../CONSTANT';
import Actions from '../Redux/Actions';
import Loader from '../Home/Loader';
import useTranslation from '../Utilities/useTranslation';
const BlogLink = React.lazy(() => import('../Links/BlogLink'))
const HomeLink = React.lazy(() => import('../Links/HomeLink'))
const ServiceLink = React.lazy(() => import('../Links/ServiceLink'))
const WhyUsLink = React.lazy(() => import('../Links/WhyUsLink'))
const FaqLink = React.lazy(() => import('../Links/FaqLink'));
const ScrollToTop = React.lazy(() => import("react-scroll-to-top"));
const IpInfo = React.lazy(() => import('../Home/IP'))

function Footer() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false)
    const { submitted, err } = useSelector(state => state[CONSTANT.REDUCERS.NEWS_LETTERS])
    const dispatch = useDispatch();
    const t = useTranslation()
    useEffect(() => {
        if (submitted) {
            setLoading(false)
            setEmail("")
            toastr.success("Subscription successful!")
        }
        if (err) {
            setLoading(false)
            toastr.error(err)
        }
        return () => {
            dispatch(Actions.NEWSLETTER.unload())
        }
    }, [submitted, err])

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(Actions.NEWSLETTER.submit(email))
    }
    return (
        <React.Suspense fallback={<Loader />}>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3 style={{ textTransform: "none" }}>{t(statickeys.COMMON_CONTENT_COMPANY_NAME)} </h3>
                                <p>{t(statickeys.CONTACT_ADDRESS)}<br /> 400072</p>
                                <br />
                                <p>
                                    <strong> {t(statickeys.COMMON_CONTENT_PHONE)} :</strong>
                                    <a href={`tel:${process.env.REACT_APP_SANDEEP_PHONE_NUMBER}`} alt="Phone link" aria-label={`91 ${process.env.REACT_APP_SANDEEP_PHONE_NUMBER}`}>
                                        &nbsp; +91 {process.env.REACT_APP_SANDEEP_PHONE_NUMBER}
                                    </a>
                                    <br />
                                    <strong> {t(statickeys.COMMON_CONTENT_EMAIL)} :</strong>
                                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`} alt="Email link" aria-label={process.env.REACT_APP_EMAIL}>
                                        &nbsp; {process.env.REACT_APP_EMAIL}
                                    </a>
                                    <br />
                                </p>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4> {t(statickeys.COMMON_CONTENT_USEFUL_LINK)}</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i><HomeLink /></li>
                                    <li><i className="bx bx-chevron-right"></i><ServiceLink /></li>
                                    <li><i className="bx bx-chevron-right"></i><FaqLink /></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>&nbsp;</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i><BlogLink /></li>
                                    <li><i className="bx bx-chevron-right"></i><WhyUsLink>{t(statickeys.COMMON_CONTENT_WHY_US)}</WhyUsLink></li>
                                    <li><i className="bx bx-chevron-right"></i><AboutLink /></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>{t(statickeys.NEWLETTER_TITLE)}</h4>
                                <p>{t(statickeys.NEWLETTER_DESC)}</p>
                                <form onSubmit={handleSubmit}>
                                    <input className='newsletter-input' value={email} onChange={(e) => { setEmail(e.target.value) }} type="email"
                                        name="email" placeholder={t(statickeys.COMMON_PLACEHOLDER_EMAIL)} autoComplete='off' required />
                                    <input type="submit" value={`${t(!loading ? statickeys.COMMON_BUTTON_SUBSCRIBE : statickeys.COMMON_BUTTON_SUBSCRIBING)}`} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-md-flex py-4">
                    <div className="me-md-auto text-center text-md-start">
                        <div className="copyright"> &copy; &nbsp;{t(statickeys.COMMON_CONTENT_COPYRIGHT)}&nbsp;
                            <strong><span style={{ cursor: "pointer" }}>{t(statickeys.COMMON_CONTENT_COMPANY_NAME)}</span></strong>&nbsp;{t(statickeys.COMMON_CONTENT_ALL_RIGHTS_RESERVED)}
                        </div>
                        <div className="credits">{t(statickeys.COMMON_LABEL_DESIGNED_BY)}</div>
                    </div>
                    <div className="social-links text-center text-md-right pt-3 pt-md-0">
                        <a target='_blank' rel='noreferrer' href={process.env.REACT_APP_WHATSAPP_PROFILE_URL} alt="whatsapp link" aria-label='Whatsapp link' className="whatsapp-link"><i className="fa fa-whatsapp"></i></a>
                        <a target='_blank' rel='noreferrer' href={process.env.REACT_APP_FACEBOOK_PROFILE_URL} alt="Facebook link" aria-label='Facebook link' className="facebook-link"><i className="fa fa-facebook"></i></a>
                        <a target='_blank' rel='noreferrer' href={process.env.REACT_APP_INSTAGRAM_PROFILE_URL} alt="Instagram link" aria-label='Instagram link' className="instagram-link"><i className="fa fa-instagram"></i></a>
                        <a target='_blank' rel='noreferrer' href={`mailto:${process.env.REACT_APP_EMAIL}`} className="email-link" alt="Email link" aria-label='Email link'><i className="fa fa-envelope text-warning"></i></a>
                    </div>
                </div>
            </footer>
            <ScrollToTop smooth={true} draggable component={<i className='fa fa-arrow-up text-white'></i>} style={{ backgroundColor: "#2AE149", bottom: "29px" }}></ScrollToTop>
            <IpInfo />
        </React.Suspense>
    )
}

export default Footer