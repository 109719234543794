import { ActionTypes } from "../Action Types"

const data = [
    { "Que": "FAQ_QUE_1", "Ans": ["FAQ_ANS_1"] },
    { "Que": "FAQ_QUE_2", "Ans": ["FAQ_ANS_2"] },
    { "Que": "FAQ_QUE_3", "Ans": ["FAQ_ANS_3"] },
    { "Que": "FAQ_QUE_4", "Ans": ["FAQ_ANS_4"] },
    { "Que": "FAQ_QUE_5", "Ans": ["FAQ_ANS_5"] },
    { "Que": "FAQ_QUE_6", "Ans": ["FAQ_ANS_6"] },
    { "Que": "FAQ_QUE_7", "Ans": ["FAQ_ANS_7"] },
    { "Que": "FAQ_QUE_8", "Ans": ["FAQ_ANS_8"] },
    { "Que": "FAQ_QUE_9", "Ans": ["FAQ_ANS_9"] },
    { "Que": "FAQ_QUE_10", "Ans": ["FAQ_ANS_10"] },
    { "Que": "FAQ_QUE_11", "Ans": ["FAQ_ANS_11"] },
    { "Que": "FAQ_QUE_12", "Ans": ["FAQ_ANS_12"] },
    { "Que": "FAQ_QUE_13", "Ans": ["FAQ_ANS_13"] },
    { "Que": "FAQ_QUE_14", "Ans": ["FAQ_ANS_14"] },
    { "Que": "FAQ_QUE_15", "Ans": ["FAQ_ANS_15"] },
    { "Que": "FAQ_QUE_16", "Ans": ["FAQ_ANS_16"] },
    { "Que": "FAQ_QUE_17", "Ans": ["FAQ_ANS_17"] },
    { "Que": "FAQ_QUE_18", "Ans": ["FAQ_ANS_18"] }
]
function fetchFaqRequest() {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.faq.FETCH_FAQ_REQUEST })
        dispatch({ type: ActionTypes.faq.FETCH_FAQ_REQUEST_SUCCESS, payload: data })
    }
}
function unloadFaq() {
    return async (dispatch) => { dispatch({ type: ActionTypes.faq.RESET_FAQ_REQUEST }); }
}
export {
    fetchFaqRequest,
    unloadFaq
}