import { ActionTypes } from "../Action Types"

const initalState = {
    countData: [],
    loading: false,
    err: ''
}

function countReducer(state = initalState, action) {
    switch (action.type) {
        case ActionTypes.counter.FETCH_COUNTER_REQUEST:
            return {
                ...state,
                loading: true,
                err: ''
            }
        case ActionTypes.counter.FETCH_COUNTER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                err: '',
                countData: action.payload
            }
        case ActionTypes.counter.FETCH_COUNTER_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload,
                countData: []
            }
        case ActionTypes.counter.RESET_COUNTER_REQUEST:
            return {
                ...state,
                ...initalState
            }
        default:
            return state;
    }
}
export default countReducer