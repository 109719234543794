import { ActionTypes } from "../Action Types"

const intialState = {
    loading: false,
    err: '',
    nwsData: [],
    submitted: false,
}

function newsLetterReducer(state = intialState, action) {
    switch (action.type) {
        case ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST:
            return {
                ...state,
                loading: true,
                submitted: false,
                err: ''
            }
        case ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                submitted: true,
                nwsData: action.payload
            }
        case ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                submitted: false,
                err: action.payload,
                nwsData: []
            }
        case ActionTypes.newsletter.RESET_NEWS_LETTER_REQUEST:
            return {
                ...state,
                submitted: false
            }
        default:
            return state
    }
}
export default newsLetterReducer;