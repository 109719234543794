import { ActionTypes } from "../Action Types"

const intialState = {
    loading: false,
    err: '',
    hwwData: [],
}

function hwwReducer(state = intialState, action) {
    switch (action.type) {
        case ActionTypes.hww.FETCH_HWW_REQUEST:
            return {
                ...state,
                loading: true,
                submitted: false,
                err: ''
            }
        case ActionTypes.hww.FETCH_HWW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                submitted: true,
                hwwData: action.payload
            }
        case ActionTypes.hww.FETCH_HWW_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                submitted: false,
                err: action.payload,
                hwwData: []
            }
        case ActionTypes.hww.RESET_HWW_REQUEST:
            return {
                ...state,
                ...intialState,
            }
        default:
            return state
    }
}

export default hwwReducer;