import $ from "jquery";
import InvokeApi from './apiHelper';
import EventDetail, { eventType } from "./eventDetail";

function GUID(initial = "") {
  let d = new Date().getTime();//Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xyyx-yxxy-xyxy-yxyx-yyxx-xxyy'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 36;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 36 | 0;
      d = Math.floor(d / 36);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 36 | 0;
      d2 = Math.floor(d2 / 36);
    }
    return `${initial}_${(c === 'x' ? r : (r & 0x3 | 0x8)).toString(36)}`;
  });
}

function ToggleSidebar() { $(".body-tag").toggleClass("box-collapse-open"); $("section").toggleClass('bg-blur'); $("#floating-button").toggleClass('hide') }
function RandomColor() { return "#" + ((1 << 24) * Math.random() | 0).toString(16) }
function scrollToTop() { window.scrollTo({ top: 0, behavior: "smooth" }) }
function SetTitle(title) { document.title = title; }
function markActive(className) { $(".active").removeClass("active"); $(`.${className}`).addClass("active"); }
function GetLocalStorageData(key) { return localStorage.getItem(key); }
function SetLocalStorageData(key, value) { return sessionStorage.setItem(key, value); }
function handleDropwownEvent(e) { $(e.target).closest(".dropdown").find("ul").first().toggleClass("dropdown-active") }
function handleEmptyLinkClick(e) { e.preventDefault(); }

const Share = (url) => {
  let currentURL = url ?? window.location.href.toString()
  if (navigator.share) { navigator.share({title: 'SBVishwakarma - Interior Solutions', url: currentURL}).then(() => { console.log('Thanks for sharing!'); }).catch(console.error);}
}

/**
 * format the templated string
 * @param {string} str template string
 * @param  {...any} args objects to be replaced from templated string
 * @returns formatted string
 */
const formatString = (str, args = {}) => {
  let tIndex = 0;
  let objStore = {};
  if (args && Object.keys(args).length !== 0) {
    return str.replace(/\{{\w+}}/g, (match, index) => {
      const key = args[match.substring(2, match.length - 2)];
      if (IsNullOrEmpty(key)) { if (IsNullOrEmpty(objStore[match])) { objStore[match] = args[tIndex++]; }}
      return IsNullOrEmpty(key) ? objStore[match] : key;
    });
  }
  return str;
};

/**
 * check if string is null, empty or undefined
 * @param {String} key
 * @param {Boolean} typeCheck
 * @returns string is null or empty
 */
const IsNullOrEmpty = (key, typeCheck) => key === null || key === undefined || key === "" || (typeCheck && (key === "null" || key === "undefined"));

export {
  eventType,
  EventDetail,
  InvokeApi,
  handleDropwownEvent,
  handleEmptyLinkClick,
  SetLocalStorageData,
  GetLocalStorageData,
  Share,
  RandomColor,
  SetTitle,
  GUID,
  scrollToTop,
  markActive,
  formatString,
  ToggleSidebar,
}

export class FieldValidator {

  err;
  constructor(obj = {}) { this.obj = obj; }
  validateEmail(email) { let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; return re.test(String(email).toLowerCase()); }
  validatePhone(phone) { let re = /^[0-9]{10}$/; return re.test(String(phone).toLowerCase()); }
  MinLength(minLength) {
    let val = Object.values(this.obj)[0].toString()
    if (val.length < minLength) { this.err = "MIN_LENGTH_ERROR" } else { this.err = null }
    return this;
  }
  MaxLength(maxLength) {
    let val = Object.values(this.obj)[0].toString()
    if (val.length > maxLength) { this.err = "MAX_LENGTH_ERROR" } else { this.err = null }
    return this;
  }
  Required(msg) {
    let val = Object.values(this.obj)[0]?.toString() ?? undefined
    if (!val) { this.err = msg ?? "REQUIRED" } else { this.err = null }
    return this;
  }
}