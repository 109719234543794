import consultReducer from "./consultReducer";
import contactReducer from "./contactReducer";
import countReducer from "./counterReducer";
import ipReducer from "./ipReducer";
import newsLetterReducer from "./newsLetterReducer";
import servicesReducer from "./servicesReducer";
import hwwReducer from './hwwReducer';
import faqReducer from "./faqReducer";
import teamReducer from "./teamReducer";
import { CONSTANT } from "../../CONSTANT";
import whyUsReducer from "./whyUsReducer";
import applicationReducer from "./applicationReducer";
const { APPLICATION, CONSULT, CONTACT, IP, TEAM, SERVICES, FAQ, NEWS_LETTERS, HOW_WE_WORK, COUNT, WHY_US } = CONSTANT.REDUCERS;

const Reducers = {}

Reducers[CONSULT] = consultReducer;
Reducers[CONTACT] = contactReducer;
Reducers[COUNT] = countReducer;
Reducers[FAQ] = faqReducer;
Reducers[IP] = ipReducer;
Reducers[SERVICES] = servicesReducer;
Reducers[TEAM] = teamReducer;
Reducers[NEWS_LETTERS] = newsLetterReducer;
Reducers[HOW_WE_WORK] = hwwReducer;
Reducers[WHY_US] = whyUsReducer;
Reducers[APPLICATION] = applicationReducer;
export default Reducers;