import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { CONSTANT, statickeys } from '../CONSTANT';
import PropTypes from 'prop-types';
import useTranslation from '../Utilities/useTranslation';
function AboutLink({ activeOnlyWhenExact }) {
  const t = useTranslation();
  let match = useMatch({
    path: CONSTANT.ROUTES.ABOUT,
    exact: activeOnlyWhenExact
  });
  let label = "About link navigation link"
  let value = t(statickeys.COMMON_CONTENT_ABOUT_US)
  return (
    <Link to={CONSTANT.ROUTES.ABOUT} className={match ? "active nav-link" : "nav-link"} alt={label} aria-label={value}>
      {
        value
      }
      <div className='nav-link-bar'></div>
    </Link>
  )
}
AboutLink.propTypes = {
  activeOnlyWhenExact: PropTypes.bool
}
export default AboutLink
