class EventDetail {
    name = "";
    time = new Date();
    page = "";
    type = "";
    addlData = {};

    constructor(name, page, type, addlData) {
        this.name = name;
        this.page = page;
        this.type = type;
        this.addlData = addlData;
    }
}

export const eventType = {
    BtnClick: "BtnClick",
    PageView: "PageView",
    FormSubmit: "FormSubmit",
    Custom: "Custom",
    Error: "Error",
}

export default EventDetail;