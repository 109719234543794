import { ActionTypes } from "../Action Types"

const data = [{
    count: 200,
    icon: "emoji-smile text-success",
    name: 'HAPPY_CUSTOMERS_TEXT',
    prefix: "",
    suffix: "+"
},
{
    count: 12,
    icon: "patch-check text-primary",
    name: 'YOE_TEXT',
    prefix: "",
    suffix: "+"
},
{
    count: 250,
    icon: "list text-danger",
    name: 'PROJECTS_TEXT',
    prefix: "",
    suffix: "+"
},
{
    count: 50,
    icon: "people-fill text-warning",
    name: 'HARD_WORKING_EMPLOYEE_TEXT',
    prefix: "",
    suffix: "+"
}]

export function fetchCounter() {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.counter.FETCH_COUNTER_REQUEST })
        dispatch({ type: ActionTypes.counter.FETCH_COUNTER_REQUEST_SUCCESS, payload: data })
    }
}

export function unloadCounter() {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.counter.RESET_COUNTER_REQUEST });
    }
}