import { CONSTANT } from "../../CONSTANT"
import { RequestContext } from "../../Utilities/HelperClasses"
import InvokeApi from "../../Utilities/apiHelper"
import { ActionTypes } from "../Action Types"

function submitNewsLetter(email) {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST })
        InvokeApi(new RequestContext("Email/subscribe", CONSTANT.HTTP_METHOD.POST, {}, {email: email}))
            .then(res => { dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_SUCCESS, payload: res.data }) })
            .catch(err => { dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_FAILURE, payload: err.response?.data?.message ?? err.message })})
    }
}

function resetNewsLetter(){
    return (dispatch) => {
        dispatch({ type: ActionTypes.newsletter.RESET_NEWS_LETTER_REQUEST })
    }
}

export {
    submitNewsLetter,
    resetNewsLetter,
}