import { ActionTypes } from "../Action Types"
import sv from '../../assets/img/Team/sv.jpg'
import jv from '../../assets/img/Team/jv.jpg'

const intialState = {
    loading: false,
    err: '',
    teamData: [
        {
            alt: "Jitendra Vishwakarma photo",
            src: jv,
            name: 'JITENDRA_VISHWAKARMA',
            role: 'CONTENT_DECORATOR',
            desc: 'JITENDRA_VISHWAKARMA_QUOTE',
            mail: process.env.REACT_APP_EMAIL,
            number: process.env.REACT_APP_JITENDRA_PHONE_NUMBER
        },
        {
            alt: "Sandeep Vishwakarma photo",
            src: sv,
            name: 'SANDEEP_VISHWAKARMA',
            role: 'CONTENT_DECORATOR',
            desc: 'SANDEEP_VISHWAKARMA_QUOTE',
            number: process.env.REACT_APP_SANDEEP_PHONE_NUMBER,
            mail: process.env.REACT_APP_EMAIL
        }
    ],
}

function teamReducer(state = intialState, action) {
    switch (action.type) {
        case ActionTypes.team.FETCH_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
                err: ''
            }
        case ActionTypes.team.FETCH_TEAM_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                teamData: action.payload
            }
        case ActionTypes.team.FETCH_TEAM_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                teamData: [],
                err: action.payload
            }
        default:
            return state
    }
}

export default teamReducer;