import React from 'react'
import { RandomColor } from '../Utilities'
import PropTypes from 'prop-types';

function Loader({ showIcon = true, randomColor = true }) {

  let color = RandomColor()
  let style =
  {
    color: randomColor ? color : "blue"
  }

  return (
    showIcon ? <div className="spinner-border justify-self-center align-self-center loader" style={style}>
      <span className="sr-only">Loading...</span>
    </div> :
      <div className=' justify-self-center align-self-center loader'>Loading.........</div>
  )
}
Loader.propTypes = {
  showIcon: PropTypes.bool,
  randomColor: PropTypes.bool
}
export default Loader