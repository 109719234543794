class RequestContext {
    /**
     * @param {URLSearchParams} QueryString parameters
     */
    queryParameters
    /**
     * @param {Object} Request Body
     */
    requestBody
    /**
     * @param {string} relative url
     */
    url
    /**
     * @param {HTTP_METHOD} Http Method
     */
    httpMethod

    /**
     *
     * @param {string} url relative URL
     * @param {any} httpMethod http method GET/POST/PUT/DELETE/PATCH
     * @param {Object} queryParameters query parameters
     * @param {Object} requestBody request body
     */
    constructor(url, httpMethod, queryParameters, requestBody) {
        this.httpMethod = httpMethod
        this.queryParameters = queryParameters
        this.requestBody = requestBody
        this.url = url
    }
}


export { RequestContext }
