import { ActionTypes } from "../Action Types"

const faqInitialState = {
    loading: false,
    err: '',
    faqData: [ ]
}

const faqReducer = (state = faqInitialState, action) => {
    switch (action.type) {
        case ActionTypes.faq.FETCH_FAQ_REQUEST:
            return {
                ...state,
                loading: true,
                err: ''
            }
        case ActionTypes.faq.FETCH_FAQ_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                faqData: action.payload,
                err: ''
            }
        case ActionTypes.faq.FETCH_FAQ_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                faqData: [],
                err: action.payload
            }
        case ActionTypes.faq.RESET_FAQ_REQUEST:
            return {
                ...state,
                ...faqInitialState
            }
        default:
            return state
    }
}

export default faqReducer;