import InvokeApi from "../../Utilities/apiHelper"
import { ActionTypes } from "../Action Types"
import { CONSTANT } from '../../CONSTANT'
import { RequestContext } from "../../Utilities/HelperClasses"

function submitContactRequest(formData) {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.contact.SUBMIT_CONTACT_REQUEST })
        InvokeApi(new RequestContext("/Contact", CONSTANT.HTTP_METHOD.POST, {}, formData))
            .then(res => {
                dispatch({ type: ActionTypes.contact.SUBMIT_CONTACT_REQUEST_SUCCESS, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: ActionTypes.contact.SUBMIT_CONTACT_REQUEST_FAILURE, payload: err.response?.data?.message ?? err.message })
            })
    }
}
function submitConsultRequest(formData){
    return async (dispatch) => {
        dispatch({ type: ActionTypes.consult.SUBMIT_CONSULT_REQUEST })
        InvokeApi(new RequestContext("/Consult", CONSTANT.HTTP_METHOD.POST, {}, formData))
            .then(res => {
                dispatch({ type: ActionTypes.consult.SUBMIT_CONSULT_REQUEST_SUCCESS, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: ActionTypes.consult.SUBMIT_CONSULT_REQUEST_FAILURE, payload: err.response?.data?.message ?? err.message })
            })
    }
}
function resetContact(){
    return async (dispatch) => {
        dispatch({ type: ActionTypes.contact.RESET_CONTACT_REQUEST })
    }
}
function resetConsult(){
    return async (dispatch) => {
        dispatch({ type: ActionTypes.consult.RESET_CONSULT_REQUEST })
    }
}
export {
    submitContactRequest,
    submitConsultRequest,
    resetContact,
    resetConsult,
}