import axios from 'axios'
import CryptoJS from 'crypto-js'
import { CONSTANT } from '../CONSTANT'
const { HTTP_METHOD } = CONSTANT
let header = {
    'x-info': `{"x-client-id":"SBVishwakarma-Admin","x-client-value":"f56532fd73951b55e7c47eb4d53ca14f"}`,
}
const Instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: header,
})
Instance.interceptors.request.use(
    function (config) {
        config.headers = {
            'Access-Control-Expose-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*',
        }

        // Do something before request is sent
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    },
)

// Instance.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   function (error) {
//     const originalRequest = error.config
//     if (
//       error.response.status === 401 &&
//       !originalRequest._retry &&
//       error.config.url === API_ENDPOINTS.ACCOUNT.REFRESH_TOKEN
//     ) {
//       originalRequest._retry = true
//       refreshAccessToken()
//       return Instance(originalRequest)
//     }
//     return Promise.reject(error)
//   },
// )
function Encrypt(text) {
    return CryptoJS.AES.encrypt(JSON.stringify(text), '68c41777578469b335248b2e27d0198aea8bf53d').toString();
}

/**
 * Encrypt body
 * @param {Object} body 
 * @returns encrypted object
 */
const encryptBody = (body) => {
    return {
        data: Encrypt(body)
    }
}


const formQueryString = (parameters) => {
    if (!parameters) {
        return ''
    }
    const qs = Object.keys(parameters)
        .map((key) => {
            return `${key}=${encodeURIComponent(parameters[key])}`
        })
        .join('&')
    return qs ? `?${qs}` : ''
}
/**
 *
 * @param {RequestContext} requestContext
 * @returns {AxiosResponse} HttpResponse object
 */
const InvokeApi = async (requestContext) => {
    return new Promise((resolve, reject) => {
        switch (requestContext.httpMethod) {
            case HTTP_METHOD.GET:
                Instance.get(requestContext.url + formQueryString(requestContext.queryParameters)).then((res) => resolve(res)).catch((err) => reject(err))
                break
            case HTTP_METHOD.POST:
                Instance.post(requestContext.url, encryptBody(requestContext.requestBody)).then((res) => resolve(res)).catch((err) => reject(err))
                break
            case HTTP_METHOD.DELETE:
                Instance.delete(requestContext.url + formQueryString(requestContext.queryParameters)).then((res) => resolve(res)).catch((err) => reject(err))
                break
            case HTTP_METHOD.PUT:
                Instance.put(requestContext.url).then((res) => resolve(res)).catch((err) => reject(err))
                break
            case HTTP_METHOD.PATCH:
                Instance.patch(requestContext.url).then((res) => resolve(res)).catch((err) => reject(err))
                break
            default:
                throw new Error('Http method is not supported')
        }
    })
}

export default InvokeApi
